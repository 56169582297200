import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    borderRadius: "50%",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: "black",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      color: "white"
    }
  },
  count: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem"
    }
  },
  label: {
    opacity: 0.4,
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(1)
    }
  }
}))

export interface Props {
  count: number,
  label: string
}

export default function ClassCountItem(props: Props) {
  const classes = useStyles()
  return <div className={classes.root}>
    <Typography className={classes.count} display={"block"} variant={"h1"}>{props.count}</Typography>
    <Typography align={"center"} className={classes.label} display={"block"}
                variant={"subtitle2"}>{props.label}</Typography>
  </div>
}