import React from "react"
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"

import MuiTableCell from "@material-ui/core/TableCell"
import clsx from "clsx"
import { useDispatch, useSelector } from "react-redux"

import { HistoryItem } from "../types"
import theme from "../../../theme"
import { Box, TableBody, Typography } from "@material-ui/core"
import moment from "moment"
import { Difficulty, getDifficultyDisplay, ScheduleDateFormats } from "../../browse/types"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { RootState } from "../../../redux/store"
import { clearSelectedWorkout, fetchWorkout } from "../accountSlice"
import BackdropLoader from "../../../components/BackdropLoader"
import { WORKOUTS_HOME } from "../../routing/Locations"
import ActiveMemberWorkoutModeChoice from "../../browse/ActiveMemberWorkoutModeChoice"

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell)

const ratio = 9 / 16
const imgWidth = 120
const useStyles = makeStyles({

  root: {
    backgroundColor: "white",
    paddingLeft: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(6)
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0
    }
  },

  mainTitle: {
    color: "black",
    margin: theme.spacing(7),
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },

  imageContainer: {
    position: "relative",
    "borderRadius": "2.5px",
    "WebkitBackdropFilter": "blur(15px)",
    "backdropFilter": "blur(15px)",
    "border": "solid 0.5px #414141",
    "letter-spacing": "normal",
    overflow: "hidden",
    [theme.breakpoints.down("xl")]: {
      "width": `100%`,
      "height": "auto",
      paddingTop: `calc(${ratio} * 100%)`
    }
  },

  innerImage: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    objectFit: "cover"
  },

  row: {
    cursor: "pointer",
    height: "105px",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      height: "80px"
    }
  },
  rowAlt: {
    backgroundColor: "#f5f5f5"
  },
  imageCell: {
    width: `${imgWidth + theme.spacing(10)}px`,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  image: {
    width: `${imgWidth}px`,
    height: `${imgWidth * ratio}px`
  },
  fullTitle: {
    color: "black"
  },
  link: {
    color: theme.palette.primary.main,
    [`@media (hover: hover)`]: {
      "&:hover": {
        cursor: "pointer",
        opacity: 0.7
      }
    }
  },

  dateTitle: {
    margin: theme.spacing(8, 0, 3, 2),
    textTransform: "uppercase"
  },
  difficulty: {
    color: "black",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  time: {
    color: "black"
  },
  subText: {
    opacity: 0.4,
    [theme.breakpoints.up("lg")]: {
      display: "none"
    }
  },
  instructor: {
    color: "black",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  emptyText: {
    color: "black",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2)
    }
  }
})


export default function HistoryList() {

  const classes = useStyles()
  const oddRowStyle = clsx(classes.row, classes.rowAlt)
  const selectedWorkout = useSelector((state: RootState) => state.account.selectedWorkout)
  const fetchingWorkout = useSelector((state: RootState) => state.account.fetchingWorkout)
  const historyItems = useSelector((state: RootState) => state.account.accountSummaryData?.historyItems)

  const theme = useTheme()
  const dispatch = useDispatch()
  const isSmallerThanMedium = useMediaQuery(theme.breakpoints.down("sm"))
  const isXS = useMediaQuery(theme.breakpoints.down("xs"))


  function Image({ thumbnailUrl }: { thumbnailUrl: string }) {
    return <Box className={classes.image} ml={4}>
      <div className={classes.imageContainer}><img className={classes.innerImage} src={thumbnailUrl}/></div>
    </Box>
  }


  return <div className={classes.root}>
    <Typography className={classes.mainTitle} variant={"h1"} align={"center"}>ACTIVITY HISTORY</Typography>

    <Box p={2} style={{ backgroundColor: "#f5f5f5", color: "black" }} display={{ xs: "block", sm: "none" }}>
      <Typography align={"center"} variant={"h2"}>ACTIVITY HISTORY</Typography>
    </Box>

    {historyItems && historyItems.length > 0 && <Table aria-label="simple table">
      <TableBody>
        {historyItems.map((row: HistoryItem, index: number) => (
          <TableRow key={row.id} className={index % 2 == (isXS ? 1 : 0) ? oddRowStyle : classes.row} onClick={() => {
            dispatch(fetchWorkout(row.wid))
          }}>
            <TableCell className={classes.imageCell}>
              <Image thumbnailUrl={row.thumbnailUrl}/>
            </TableCell>
            <TableCell className={classes.time}><Typography variant={"body2"}
                                                            display={"block"}>{moment(row.classTimestamp).format(ScheduleDateFormats.longDate)}</Typography></TableCell>
            <TableCell className={classes.difficulty}><Typography
              variant={"body2"}>{getDifficultyDisplay(row.difficulty as Difficulty)}</Typography></TableCell>
            <TableCell className={classes.instructor}><Typography
              variant={"body2"}>{row.subtitle}</Typography></TableCell>
            <TableCell className={classes.fullTitle}><Typography variant={"body2"} className={classes.link}
                                                                 display={"block"}>{row.title}</Typography><Typography
              variant={"subtitle1"} className={classes.subText}
              display={"block"}>{isSmallerThanMedium ? `${getDifficultyDisplay(row.difficulty as Difficulty)} • ` : ``}{row.subtitle}</Typography></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>}
    {historyItems && historyItems.length == 0 &&
    <Typography className={classes.emptyText} variant={"body1"} align={"center"}>Your workout history shows up here
      :)</Typography>}
    {fetchingWorkout && <BackdropLoader show={fetchingWorkout}/>}
    <ActiveMemberWorkoutModeChoice workout={selectedWorkout} selectionType={"on-demand"}
                                   backurl={WORKOUTS_HOME}
                                   cleanup={() => dispatch(clearSelectedWorkout())}/>
  </div>
}

