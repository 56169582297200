import { Link } from "gatsby"
import React, { useState } from "react"
import ChangeEmailDialog from "./ChangeEmailDialog"

export default function ChangeEmailLink() {
  const [showDialog, setShowDialog] = useState(false)

  return <div>
    <Link to={"#"} onClick={() => setShowDialog(true)}>Change Email</Link>
    <ChangeEmailDialog open={showDialog} onClose={() => setShowDialog(false)}/>
  </div>
}