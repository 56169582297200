import { withStyles } from "@material-ui/core/styles"
import { TextField } from "@material-ui/core"
import React, { useState } from "react"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import { Visibility, VisibilityOff } from "@material-ui/icons"

// @ts-ignore
const StyledTextField = withStyles({})(TextField)

export default StyledTextField

export function PasswordTextField({ InputProps = {}, ...props }) {
  const [showPassword, setShowPassword] = useState(props.show)
  const handleClickShowPassword = () => setShowPassword(!showPassword)

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      InputProps={{
        ...InputProps, endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <VisibilityOff/> : <Visibility/>}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  )
}


