import { ResetPasswordDetails, ResetPasswordFormErrors } from "../types"
import { isValidString, validateEmail } from "../../../utils"

export default function validate(details: ResetPasswordDetails): ResetPasswordFormErrors {
  let errors: ResetPasswordFormErrors = {}

  if (!isValidString(details.email)) {
    errors.email = "Please enter your email"
  } else if (!validateEmail(details.email ?? "")) {
    errors.email = "Email address is invalid"
  }

  return errors
};