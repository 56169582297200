import { makeStyles } from "@material-ui/core/styles"
import React, { useEffect } from "react"
import Backdrop from "@material-ui/core/Backdrop"
import CloseButton from "../../../components/CloseButton"
import { Box, CircularProgress, Typography } from "@material-ui/core"
import useForm from "../../register/useForm"
import StyledTextField, { PasswordTextField } from "../../../components/TextFieldInput"
import { useDispatch, useSelector } from "react-redux"
import StyledButton from "../../../components/StyledButton"
import { ChangeEmailDetails } from "../types"
import { changeEmail, clearChangeEmail } from "../accountSlice"
import { RootState } from "../../../redux/store"
import StyledSnackbar from "../../../components/StyledSnackbar"
import validate from "./ChangeEmailValidationRules"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "90%",
    color: "white",
    margin: "auto"
  },
  body: {
    minWidth: "350px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0, 0, 0, 0.92)"
  },
  okButton: {
    width: "240px",
    margin: "auto"
  }
}))

export interface Props {
  open: boolean
  onClose: () => void
}

export default function ChangeEmailDialog(props: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const changeEmailData = useSelector((state: RootState) => state.account.changeEmailData)

  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useForm(() => dispatch(changeEmail(values as ChangeEmailDetails)), validate)

  useEffect(() => {
    return () => {
      dispatch(clearChangeEmail())
    }
  }, [props.open])

  return <Backdrop className={classes.backdrop} open={props.open}>
    <CloseButton clicked={props.onClose}/>
    <div className={classes.root}>
      {changeEmailData.loading && <CircularProgress/>}
      {(!changeEmailData.loading && !changeEmailData.success) && <div>
        <Typography align={"center"} variant={"h2"}>CHANGE EMAIL</Typography>
        <div className={classes.body}>
          <Box mt={1} mb={3}>
            <Typography align={"center"} variant={"body2"}>
              Fill in the details to change your email.
            </Typography>
          </Box>
          <Box mb={2}>
            <PasswordTextField
              show={false}
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Enter current password"
              id="password"
              helperText={errors.password}
              error={errors.email !== undefined}
              onChange={handleChange}
              autoComplete="current-password"
            />
            <StyledTextField
              variant="outlined"
              margin="normal"
              fullWidth={true}
              id="email"
              label="Enter new email"
              name="email"
              autoComplete="email"
              value={values.email || ""}
              helperText={errors.email}
              onChange={handleChange}
              error={errors.email !== undefined}
            />
          </Box>
          <StyledButton text={"CHANGE NOW"} onClick={handleSubmit}/>
        </div>
      </div>}

      {changeEmailData.success && <div>
        <Typography align={"center"} variant={"h2"}>SUCCESS</Typography>
        <Box mt={4} mb={3}>
          <Typography align={"center"} variant={"body1"}>Your email has been changed
            to {changeEmailData!.email}</Typography>
        </Box>
        <StyledButton className={classes.okButton} text={"ok"} onClick={() => props.onClose()}/>
      </div>}

      {changeEmailData.error && <StyledSnackbar show={true} severity={"error"} message={changeEmailData!.error!.message}
                                                showTime={changeEmailData!.error!.errorTime}/>}
    </div>
  </Backdrop>
}