import { Link } from "gatsby"
import React, { useState } from "react"
import ResetPasswordDialog from "../ResetPassword/ResetPasswordDialog"

export default function ChangePasswordLink() {
  const [showChangePassword, setShowChangePassword] = useState(false)

  return <div>
    <Link to={"#"} onClick={() => setShowChangePassword(true)}>Change Password</Link>
    <ResetPasswordDialog mode={"change"} open={showChangePassword} onClose={() => setShowChangePassword(false)}/>
  </div>
}