import { makeStyles } from "@material-ui/core/styles"
import React, { FormEvent } from "react"
import { Typography } from "@material-ui/core"
import { LinkHoverTap } from "../../../components/AnimationEffects"
import { FileButton } from "../../../components/FileButton"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { uploadProfilePic } from "../accountSlice"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "135px",
    height: "135px",
    position: "relative",
    [theme.breakpoints.up("xl")]: {
      width: "150px",
      height: "150px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "100px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "55px",
      height: "55px"
    }
  },
  imageRoot: {
    display: "flex",
    backgroundColor: "black",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "white"
    }
  },

  image: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    objectFit: "cover",
    position: "absolute",
    padding: "1px",
    top: 0,
    left: 0
  },

  edit: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.primary.main,
    textTransform: "lowercase",
    letterSpacing: 0.3,
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      left: "17px"
    },
    "&:hover": {
      cursor: "pointer"
    }
  },
  inital: {
    color: "white",
    textTransform: "uppercase",
    fontSize: "3rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      color: "black"
    }
  },
  opacityLow: {
    opacity: 0.25
  }
}))

export interface Props {
  initial: string
  profileImageUrl?: string | null
}

export default function ProfileImage(props: Props) {
  const classes = useStyles()
  const profileImageUrl = useSelector((state: RootState) => state.account.accountSummaryData?.profileImageUrl)
  const dispatch = useDispatch()

  function onFileChanged(e: FormEvent<HTMLInputElement>) {
    let file = (e.target as HTMLInputElement).files![0]
    if (file)
      dispatch(uploadProfilePic(file))
  }

  return <FileButton onChange={onFileChanged}>
    <div className={classes.root}>
      <div className={clsx({ [classes.imageRoot]: true, [classes.opacityLow]: profileImageUrl !== null })}>
        <Typography className={classes.inital} variant={"body1"} align={"center"}>{props.initial}</Typography>
      </div>

      {profileImageUrl && <img className={classes.image} src={profileImageUrl}/>}

      <LinkHoverTap>
        <Typography className={classes.edit} align={"center"} variant={"body2"}>edit</Typography>
      </LinkHoverTap>
    </div>
  </FileButton>
}