import React from "react"
// @ts-ignore
import { AuthenticatedOrSSRRoute } from "../routing/Routes"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import SEO from "../../components/seo"
import useHeight from "../videochat/hooks/useHeight/useHeight"
import AccountSummary from "./Summary/AccountSummary"
import MenuWithLogo from "../menu/MenuWithLogo"
import HistoryList from "./History/HistoryList"
import StyledSnackbar from "../../components/StyledSnackbar"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import BackdropLoader from "../../components/BackdropLoader"
import useMediaQuery from "@material-ui/core/useMediaQuery"


const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    minHeight: "100vh"
  },
  body: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      flexGrow: 0
    }
  }

}))


function Account() {
  const classes = useStyles()
  const height = useHeight()
  const theme = useTheme()
  const isXS = useMediaQuery(theme.breakpoints.down("xs"))
  const error = useSelector((state: RootState) => state.account.error)
  const loading = useSelector((state: RootState) => state.account.loading)

  return <div>
    <SEO title={"Profile"}/>
    <div style={{ minHeight: height == "0px" ? "100vh" : height }} className={classes.root}>
      <MenuWithLogo memberMenu={true} showWelcome={false} blackIcon={!isXS}/>

      <div className={classes.body}>
        <AccountSummary/>

        <div className={classes.content}>
          <HistoryList/>
        </div>
      </div>

    </div>

    {error && <StyledSnackbar show={true} severity={"error"} message={error.message} showTime={error.errorTime}/>}
    <BackdropLoader show={loading}/>
  </div>

}

export default function AccountWrapper() {
  return <AuthenticatedOrSSRRoute component={Account}/>
}