import React, { FunctionComponent, useRef } from "react"
import { Button } from "@material-ui/core"

type Props = {
  onChange(e: React.FormEvent<HTMLInputElement>): void
}

export const FileButton: FunctionComponent<Props> = ({ onChange, children }) => {
  const inputEl = useRef<HTMLInputElement>(null)

  return (
    <div>
      <input
        type={"file"}
        ref={inputEl}
        accept="image/png, image/jpeg, image/jpg"
        onChange={onChange}
        style={{ display: "none" }}/>

      <Button onClick={() => {
        inputEl.current?.click()
      }}>
        {children}
      </Button>
    </div>
  )
}