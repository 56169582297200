import { makeStyles } from "@material-ui/core/styles"
import React, { useEffect } from "react"
import Backdrop from "@material-ui/core/Backdrop"
import CloseButton from "../../../components/CloseButton"
import { Box, CircularProgress, Typography } from "@material-ui/core"
import useForm from "../../register/useForm"
import StyledTextField from "../../../components/TextFieldInput"
import { useDispatch, useSelector } from "react-redux"
import StyledButton from "../../../components/StyledButton"
import { ResetPasswordDetails } from "../types"
import { clearResetPassword, sendResetPasswordEmail } from "../accountSlice"
import { RootState } from "../../../redux/store"
import validate from "./ResetPasswordValidationRules"
import StyledSnackbar from "../../../components/StyledSnackbar"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "90%",
    color: "white"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0, 0, 0, 0.92)"
  },
  okButton: {
    width: "240px",
    margin: "auto"
  }
}))

export interface Props {
  mode: "change" | "reset"
  open: boolean
  onClose: () => void
}

export default function ResetPasswordDialog(props: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const resetPasswordData = useSelector((state: RootState) => state.account.resetPasswordData)

  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useForm(() => dispatch(sendResetPasswordEmail(values as ResetPasswordDetails)), validate)

  useEffect(() => {
    if (props.mode === "change" && props.open) {
      dispatch(sendResetPasswordEmail({}))
    }

    return () => {
      dispatch(clearResetPassword())
    }
  }, [props.mode, props.open])

  return <Backdrop className={classes.backdrop} open={props.open}>
    <CloseButton clicked={props.onClose}/>
    <div className={classes.root}>
      {resetPasswordData?.loading && <CircularProgress/>}

      {props.mode === "reset" && (resetPasswordData == null || (!resetPasswordData.loading && !resetPasswordData.success)) &&
      <div>
        <Typography align={"center"} variant={"h2"}>RESET PASSWORD</Typography>
        <Box mt={1} mb={3}>
          <Typography align={"center"} variant={"body2"}>
            Enter your email to receive password reset instructions.
          </Typography>
        </Box>
        <Box mb={2}>
          <StyledTextField
            variant="outlined"
            margin="normal"
            fullWidth={true}
            id="email"
            label="Enter email"
            name="email"
            autoComplete="email"
            value={values.email || ""}
            helperText={errors.email}
            onChange={handleChange}
            error={errors.email !== undefined}
          />
        </Box>
        <StyledButton text={"Email Instructions"} onClick={handleSubmit}/>
      </div>}

      {resetPasswordData?.success && <div>
        <Typography align={"center"}
                    variant={"h2"}>{props.mode == "change" ? `CHANGE PASSWORD` : `RESET PASSWORD`}</Typography>
        <Box mt={4} mb={3}>
          <Typography align={"center"} variant={"body1"}>An email with instructions
            to {props.mode == "change" ? `change` : `reset`} your password has been sent
            to {resetPasswordData!.email}</Typography>
        </Box>
        <StyledButton className={classes.okButton} text={"ok"} onClick={() => props.onClose()}/>
      </div>}

      {resetPasswordData?.error && <StyledSnackbar show={true} onClose={() => {
        if (props.mode == "change") {
          props.onClose()
        }
      }} severity={"error"} message={resetPasswordData!.error!.message}
                                                   showTime={resetPasswordData!.error!.errorTime}/>}
    </div>
  </Backdrop>
}