import ProfileImage from "./ProfileImage"
import React, { useEffect } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Box, Divider, Typography } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { fetchAccountSummary } from "../accountSlice"
import ClassCountItem from "./ClassCountItem"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ChangePasswordLink from "../ChangePassword/ChangePasswordLink"
import ChangeEmailLink from "../ChangeEmail/ChangeEmailLink"

const useStyles = makeStyles((theme) => ({

  root: {
    marginLeft: theme.spacing(6),
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "black",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      flexDirection: "row",
      alignItems: "center",
      justifyItems: "center",
      alignContent: "center",
      justifyContent: "space-evenly",
      backgroundColor: "black"
    }
  },

  nameEmailContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      alignContent: "center",
      alignItems: "center"
    }
  },

  name: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      color: "white"
    }
  },

  email: {
    opacity: 0.4,
    [theme.breakpoints.down("xs")]: {
      color: "white"
    }
  },

  counts: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },

  divider: {
    width: "135px",
    backgroundColor: "black",
    opacity: 0.05,
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  }
}))

export default function AccountSummary() {
  const classes = useStyles()
  const accountSummaryData = useSelector((state: RootState) => state.account.accountSummaryData)
  const dispatch = useDispatch()
  const theme = useTheme()
  const isXS = useMediaQuery(theme.breakpoints.down("xs"))

  useEffect(() => {
    dispatch(fetchAccountSummary())
  }, [])

  return <Box pt={{ xs: 4, sm: 12 }} pl={{ xs: 0, sm: 0 }} pb={{ xs: 4, sm: 0 }} className={classes.root}>

    <div className={classes.nameEmailContainer}>
      <ProfileImage initial={accountSummaryData?.initial ?? ""}/>
      <Box ml={{ xs: 2, sm: 0 }}>
        <Typography align={isXS ? "left" : "center"} className={classes.name}
                    variant={"body1"}>{accountSummaryData?.name}</Typography>
        <Typography align={isXS ? "left" : "center"} className={classes.email}
                    variant={"subtitle1"}>{accountSummaryData?.email}</Typography>
      </Box>
    </div>


    <div className={classes.counts}>
      <ClassCountItem count={accountSummaryData?.numClasses ?? 0} label={`Total\nclasses`}/>
      <Box ml={3}>
        <ClassCountItem count={accountSummaryData?.numClassesThisWeek ?? 0} label={`Classes\nthis week`}/>
      </Box>
    </div>


    <Box display={{ xs: "block", sm: "none" }}>
      <ClassCountItem count={accountSummaryData?.numClasses ?? 0} label={`Total\nclasses`}/>
    </Box>
    <Box display={{ xs: "block", sm: "none" }}>
      <ClassCountItem count={accountSummaryData?.numClassesThisWeek ?? 0} label={`Classes\nthis week`}/>
    </Box>

    <Divider className={classes.divider}/>

    <Box mt={1} display={{ xs: "none", sm: "block" }}>
      <ChangePasswordLink/>
    </Box>

    <Box mt={1} display={{ xs: "none", sm: "block" }}>
      <ChangeEmailLink/>
    </Box>
  </Box>
}