import { ChangeEmailDetails, ChangeEmailFormErrors } from "../types"
import { isValidString, validateEmail } from "../../../utils"

export default function validate(details: ChangeEmailDetails): ChangeEmailFormErrors {
  let errors: ChangeEmailFormErrors = {}

  if (!isValidString(details.email)) {
    errors.email = "Please enter your email"
  } else if (!validateEmail(details.email ?? "")) {
    errors.email = "Email address is invalid"
  }
  if (!details.password || details.password.length == 0) {
    errors.password = "Please enter your password"
  }

  return errors
};